import * as API from '@/api/index'

 export default {
     // 收支方式列表
     all: params => {
         return API.POST('api/additionalPayments/all', params)
     },
     //新增收支
     create: params => {
         return API.POST('api/additionalPayments/create', params)
     },
     //编辑收支
     update:params => {
         return API.POST('api/additionalPayments/update', params)
     },
     //收支方式顺序调整
     move:params => {
         return API.POST('api/additionalPayments/move', params)
     },
 
 }